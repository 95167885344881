export const EDITOR_FORMATTING_OPTIONS = [
	{ text: 'Red Button', value: 'a.button button-1' },
	{ text: 'Black Button', value: 'a.button button-2' },
	{ text: 'Blackout/Hidden', value: 'span.blackout' }
];

export const EDITOR_TOOLS = [
	'bold',
	'italic',
	'underline',
	'insertUnorderedList',
	'insertOrderedList',
	'createLink',
	'separator',
	'justifyLeft',
	'justifyCenter',
	'justifyRight',
	'justifyFull',
	'separator',
	'viewHtml'
];