// region Form Field State Classes
// Handles input states as class names on the parent to assist with styling when needed
const formFieldSelector = '.form-field input, .form-field textarea, .form-field select';
const prefilledTypes = ['date', 'month']; // These always have text in the text box, so the label should always be in the active state
$(formFieldSelector).each(function () {
    $(this).closest('.form-field').toggleClass('not-empty', $(this).val() !== '');

    if ($(this).attr('required') && $(this).attr('required') !== '') {
        $(this).closest('.form-field').addClass('field-required');
        $(this).closest('.form-group').addClass('group-has-required');
    }

    if (!!prefilledTypes.filter(e => e === this.type)[0]) {
        $(this).closest('.form-field').addClass('prefilled');
    }
});

$(document).on('focus', formFieldSelector, function () {
    $(this).closest('.form-field').addClass('focused');
});

$(document).on('blur', formFieldSelector, function () {
    $(this).closest('.form-field').removeClass('focused');
});

$(document).on('keydown keyup change load input', formFieldSelector, function () {
    $(this).closest('.form-field').toggleClass('not-empty', $(this).val() !== '');
});

var dateFirst = $('#PreferredDate');
var dateLast = $('#SecondaryDate');

dateFirst.datepicker({
    minDate: 0,
    nextText: '<i class="fas fa-angle-right"></i>',
    prevText: '<i class="fas fa-angle-left"></i>',
    onSelect: function () {
        var date2 = dateFirst.datepicker('getDate');
        date2.setDate(date2.getDate() + 1);
        dateLast.datepicker('setDate', date2);
        dateLast.datepicker('option', 'minDate', date2);
    }
});

dateLast.datepicker({
    minDate: 0,
    nextText: '<i class="fas fa-angle-right"></i>',
    prevText: '<i class="fas fa-angle-left"></i>',
    onClose: function () {
        var dt1 = dateFirst.datepicker('getDate');
        var dt2 = dateLast.datepicker('getDate');
        if (dt2 <= dt1) {
            var minDate = dateLast.datepicker('option', 'minDate');
            dateLast.datepicker('setDate', minDate);
        }
    }
});

// Add event handler when focused on an element with which has datepicker intialised
$('.form-date-select').on('focus', function () {

    // store the element for use inside the position function
    var $target = $(this);

    // get the elements datepicker widget object and set it's position based on the target element
    $target.datepicker('widget').position({

        my: 'left top',
        at: 'left bottom',
        of: $target
    });
});

$('.form-ajax').on('submit', function (e) {
    e.preventDefault();

    const $form = $(this);
    const $wrap = $form.parent('.form-wrapper');
    const $message = $wrap.find('.form-success');
    const $btn = $form.find('.button--formsubmit');

    const action = $form.attr('action');
    const data = new FormData($form[0]);

    let parsleyInstance = $form.parsley();

    parsleyInstance.on('field:error', (fieldInstance) => {
        console.log(fieldInstance.validationResult);
        if (!$(fieldInstance.$element).is(':visible')) {
            fieldInstance.validationResult = true;
        }
    });

    if (parsleyInstance.isValid()) {
        $btn.prop('disabled', true);
        //Begin ajax method
        $.ajax({
            url: action,
            method: 'POST',
            data: data,
            processData: false,
            contentType: false,
            encType: 'multipart/form-data',
            success: function (response) {
                $message.show('slow');
                $message.focus();
                $form[0].reset();
                $form.hide('slow');
            }, error: function (err) {
                //alert(err.responseText);
                $(".g-recaptcha").addClass("recaptcha-border");
                $('#error-msg').show("slow");
                $('#error-msg').focus();

            },
            complete: function () {
                $btn.prop('disabled', false);
                if (grecaptcha) grecaptcha.reset();
            }
        });
    }
});