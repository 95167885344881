$(function () {
	var dateOT = $('#ot-date');

	dateOT.datepicker({
		minDate: 0,
		nextText: '<i class="fas fa-angle-right"></i>',
		prevText: '<i class="fas fa-angle-left"></i>'
	});

	var dateOT = $('#footer-ot-date');

	dateOT.datepicker({
		minDate: 0,
		nextText: '<i class="fas fa-angle-right"></i>',
		prevText: '<i class="fas fa-angle-left"></i>'
	});

	// Add event handler when focused on an element with which has datepicker intialised
	$('.ot-date').on('focus', function () {

		// store the element for use inside the position function
		var $target = $(this);

		// get the elements datepicker widget object and set it's position based on the target element
		$target.datepicker('widget').position({

			my: 'left top',
			at: 'left bottom',
			of: $target
		});
	});

	// Opentable widget slide
	$('.ot-button').click(function (e) {
		e.preventDefault();
		$('.ot-info').slideToggle(900);

		if ($('.header').hasClass('nav-open')) {
			$('.header').removeClass('nav-open').trigger('nav.toggle');
		}
	});

	$(window).on('wheel touchmove', function () {
		$('.ot-info').slideUp(900);
	});

	$('.ot-form').on('submit', function (e) {
		e.preventDefault();

		if(typeof ga !== 'undefined') {
			ga('send', 'event', 'submit', 'click', 'RFP');
		}

		const location = 1902;
		const party = $(this).find('.ot-party-size').val();
		const date = $(this).find('.ot-date').val();
		const parts = date.split('/');
		let time = $(this).find('.ot-time').val();
		const locationPath = 'milwaukee-chophouse';

		let newDate = '';

		if (parts[1].length < 2) {
			newDate = parts[2] + '-0' + parts[0] + '-' + parts[1];
			if (parts[0].length < 2) {
				newDate = parts[2] + '-0' + parts[0] + '-0' + parts[1];
			}
		} else {
			newDate = parts[2] + '-' + parts[0] + '-' + parts[1];
			if (parts[0].length < 2) {
				newDate = parts[2] + '-' + parts[0] + '-0' + parts[1];
			}
		}

		time = time.replace(':', '%3A');
		const currentDate = newDate + 'T' + time;
		const cDate = new Date();
		const cMonth = (((cDate.getMonth() + 1) < 10) ? '0' : '') + (cDate.getMonth() + 1);
		const cDay = ((cDate.getDate() < 10) ? '0' : '') + cDate.getDate();
		const cHours = ((cDate.getHours() < 10) ? '0' : '') + cDate.getHours();
		const cMin = ((cDate.getMinutes() < 10) ? '0' : '') + cDate.getMinutes();
		const searchDate = cDate.getFullYear() + '-' + cMonth + '-' + cDay + 'T' + cHours + '%3A' + cMin;
		const url = 'https://www.opentable.com/' + locationPath + '?covers=' + party + '&datetime=' + currentDate + '&restref=' + location + '&searchdatetime=' + searchDate + '&partysize=' + party + '&lang=en';

		window.open(url);
	});

	$('.ot-close').on('click', function (e) {
		e.preventDefault();
		$('.ot-info').slideToggle(900);
	});
});