import { isEditMode } from './helpers';

$(function () {
	if (!isEditMode) {
		$('.hero').each(function () {
			const $sliderBody = $(this).find('.hero-slider-body');
			const $sliderDots = $(this).find('.hero-slider-dots');
			const $banner = $(this).find('.hero-banner');

			$sliderBody.slick({
				fade: true,
				infinite: true,
				speed: 500,
				dots: true,
				arrows: false,
				autoplay: true,
				autoplaySpeed: 7500,
				pauseOnHover: false,
                appendDots: $sliderDots,
                lazyLoad: 'ondemand'
			});

			if ($(window).width() <= 768) {
				$(this).css('margin-bottom', -($banner.height() / 2) + 32);
			}

		});
	}
});