import { isEditMode } from './helpers';

$(function () {
	//return false;
	if (!isEditMode) {
		$('.slider-copy').each(function () {
			const $sliderNav = $(this).find('.slider-copy-nav');
			const $sliderFor = $(this).find('.slider-copy-for');
			const $sliderArrows = $(this).find('.slider-copy-arrows');
			const $banner = $(this).find('.hero-banner');
			const $sliderPrev = $sliderArrows.find('.slick-arrow-prev');
			const $sliderNext = $sliderArrows.find('.slick-arrow-next');

			$sliderNav.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				speed: 500,
				useCSS: false,
				arrows: false,
				asNavFor: $sliderFor
			});

			$sliderFor.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				infinite: true,
				speed: 500,
				useCSS: false,
				asNavFor: $sliderNav
			});

			$sliderPrev.on('click', function () {
				$sliderFor.slick('slickPrev');
			});

			$sliderNext.on('click', function () {
				$sliderFor.slick('slickNext');
			});

			if ($(window).width() <= 768) {
				$(this).css('margin-bottom', -($banner.height() / 2) + 32);
			}
		});
	}
});