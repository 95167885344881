import '@fortawesome/fontawesome-pro/js/all.min';
import 'slick-carousel';

import AOS from 'aos';

import './site/helpers';
import './site/navigation';
import './site/hero';
import './site/form';
import './site/menus';
import './site/open-table';
import './site/slider-copy';
import './site/instagram-widget';

import { isEditMode, scrollToElement } from './site/helpers';
import * as ADMIN_CONSTANTS from './admin/admin-constants';

window.ADMIN_CONSTANTS = ADMIN_CONSTANTS;

function setHeaderMargin() {
	const headHeight = $('#header').height();
	$('#body-content').css('margin-top', headHeight);

}

if (!isEditMode) {
	$('#css-main').on('load', setHeaderMargin);

	if ('ResizeObserver' in window) { // I'll give Chrome credit for implementing this one :eyeroll:
		const observer = new ResizeObserver(setHeaderMargin);

		observer.observe(document.querySelector('#header'));
	} else if ('MutationObserver' in window) { // Most other browsers
		const observer = new MutationObserver(setHeaderMargin);

		observer.observe(document.querySelector('#header'), {
			attributes: true,
			childList: true,
			characterData: true,
			subtree: true
		});
	} else {
		$(window).on('load', setHeaderMargin);
	}
}

$(function () {
	$('a[href^=http]').attr('target', '_blank');
	$('a[href^=https]').attr('target', '_blank');

	$(document).on('click', 'a[href^="#"]', function (e) {
		const targetSelector = $(this).attr('href');

		if (targetSelector === '#' && $(this).hasClass('back-to-top')) {
			$('html, body').animate({
				scrollTop: 0
			}, 1000);
			return false;
		}

		if (scrollToElement(targetSelector)) e.preventDefault();
	});

	$('#footer-email').on('scrollto', function () {
		$(this).focus();
	});

	let lastScrollTop = $(window).scrollTop();
	$(window).on('scroll', function (e) {
		const st = $(window).scrollTop();
		$('.back-to-top').toggleClass('visible', st > 32 && st - lastScrollTop < 0);
		lastScrollTop = st;
	});
});

if (!isEditMode) {
	$('[data-aos]').each(function () {
		// AOS doesn't let you set a default for this...
		$(this).attr('data-aos-anchor-placement', 'center-bottom');
	});

	AOS.init({
		once: true,
		offset: 0
	});
}

setInterval(function () {
	AOS.refresh();
	AOS.refreshHard();
}, 1000 / 60);