$(document).ready(function () {
	$('.instagram-slider').each(function () {
		const $sliderInsta = $(this).find('.instagram-carousel');
		const $sliderArrows = $(this).find('.instagram-slider-arrows');
		const $sliderPrev = $sliderArrows.find('.slick-arrow-prev');
		const $sliderNext = $sliderArrows.find('.slick-arrow-next');

		$sliderInsta.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			lazyLoading: 'ondemand',
			initialSlide: 0,
			infinite: true,
			speed: 500,
			useCSS: false,
			arrows: false
		});

		$sliderPrev.on('click', function () {
			$sliderInsta.slick('slickPrev');
		});

		$sliderNext.on('click', function () {
			$sliderInsta.slick('slickNext');
		});
	});
});
